import CardsSuccessTemplate  from '../components/CardsSuccessTemplate'

import { useResult } from '../hooks'

const AzulPlatinumCard = () => {
  useResult({})

  return <CardsSuccessTemplate />
}

export default AzulPlatinumCard
